import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core'; 
import NavBar from './components/NavBar'
import Grid from './components/Grid'
import Footer from './components/Footer'
// import Chatbot from './components/chatbot';
import './App.css';
import AdSense from './components/AdSense';
// import { Helmet } from 'react-helmet';
import { CircularProgress, Button } from '@material-ui/core';
 

//changes to imports 
import SecurityIcon from '@material-ui/icons/Security';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ComputerIcon from '@material-ui/icons/Computer';
import HttpIcon from '@material-ui/icons/Http';
import MenuBookOutlinedIcon from    '@material-ui/icons/MenuBookOutlined';

//chatGPT api
import axios from "axios";
import { useState } from 'react';
import { BookOutlined } from '@material-ui/icons';

const theme = createTheme({
  palette: {
    primary: {
      main:"#2e1667",
    },
    secondary: {
      main:"#c7d8ed",
    },
  },
  typography: {
    fontFamily: [
      'Roboto'
    ],
    h4: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '2rem',
      },
    h5: {
      fontWeight: 100,
      lineHeight: '2rem',
    },
  },
});

const styles = makeStyles({
  wrapper: {
    width: "65%",
    margin: "auto",
    textAlign: "center"
  },
  bigSpace: {
    marginTop: "5rem"
  },
  littleSpace:{
    marginTop: "2.5rem",
  },
  grid:{
    display: "flex", 
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap", 
  },
})

function App() {

  //ChatGPT code
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);


    axios
      .post("https://us-central1-cookerywithai.cloudfunctions.net/api/chat", { prompt }) // Make a POST request to the Cloud Function endpoint
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //ChatGPT code


  const classes = styles(); 

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <NavBar/>
        {/* <Helmet>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
        </Helmet> */}
        {/* Integrating Adsense into the webpage */}

        <div className={classes.wrapper}>
          <Typography variant="h4" className={classes.bigSpace} color="primary">
             The world's recipe at your finger tips
          </Typography>
      
      <div className="main-chat-container">
      <div id="main-chat-default" className="main-chat-chat">
        <div className="main-chat-content">
          <div className="main-chat-conversation">
            <div className="main-chat-reply main-chat-ai main-chat-rendered">
              <span className="main-chat-name">
                <div className="main-chat-name-text">CookingBot:</div>
              </span>
              <span className="main-chat-text">
                <span>
                  <span>Hi! What are we cooking today?</span>
                </span>
              </span>
            </div>
          </div>
          <div className="main-chat-input">
          <form className="main-chat-form" onSubmit={handleSubmit}>
            <div className="main-chat-input-text">
              <textarea  value = {prompt}
                // onChange={handleChange}
                onChange={(e) => setPrompt(e.target.value)}

                placeholder="Type your question..."
                maxLength="512"
              ></textarea>
            </div>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? <CircularProgress size={50} /> : 'Send'}
            </Button>


          </form>
          </div>
        </div>
        <p>{response}</p>
      </div>
    </div>
        </div>

        <div className={`${classes.grid} ${classes.bigSpace}`}>
          <Grid icon={<MenuBookOutlinedIcon style={{fill: "#4360A6", height:"125", width:"125"}}/>}  title="Perfect trimasu" btnTitle="Show me More" />
          <Grid icon={<MenuBookOutlinedIcon style={{fill: "#449A76", height:"125", width:"125"}}/>} title="Reliable" btnTitle="Show me More"/>
          <Grid icon={<MenuBookOutlinedIcon style={{fill: "#D05B2D", height:"125", width:"125"}}/>}  title="Performant" btnTitle="Show me More"/>
        </div>
        <div className={`${classes.grid} ${classes.littleSpace}`}>  
          <Grid icon={<MenuBookOutlinedIcon style={{fill: "#5EA780", height:"125", width:"125"}}/>}  title="Modular" btnTitle="Show me More"/>
          <Grid icon={<MenuBookOutlinedIcon style={{fill: "#E69426", height:"125", width:"125"}}/>}  title="Multi-Platform" btnTitle="Show me More"/>
          <Grid icon={<MenuBookOutlinedIcon style={{fill: "#2EA09D", height:"125", width:"125"}}/>} title="Connected" btnTitle="Show me More"/>
        </div>
        <div className={classes.bigSpace}>
          <Footer/>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;